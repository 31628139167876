import { defineStore } from 'pinia';
import { onMounted, ref } from 'vue';
import { can, usePersonTypes } from '@/composables';
import { CustomerSuccessPartnerService } from '@/services';
import { CustomerSuccessPartnerInterface } from '@/types';
import { PERMISSIONS } from '@/enum';

export const useCustomerSuccessPartnerStore = defineStore('CustomerSuccessPartnerStore', () => {
  const loading = ref<boolean>(false);
  const customerSuccessPartners = ref<CustomerSuccessPartnerInterface[]>([]);
  const selectedDashboardCustomerSuccessPartners = ref<CustomerSuccessPartnerInterface[]>([]);
  const isAdmin = usePersonTypes.isSuperAdmin() || usePersonTypes.isSupportAdmin() || usePersonTypes.isBillingAdmin();
  const canGetCustomerSuccessPartners = ref<boolean>(can([PERMISSIONS.CLINICIAN_ANALYTICS]) && isAdmin);

  const getCustomerSuccessPartners = async () => {
    if (customerSuccessPartners.value.length || !canGetCustomerSuccessPartners.value) return;

    loading.value = true;
    const response = await CustomerSuccessPartnerService.getCustomerSuccessPartners();
    customerSuccessPartners.value = response.data.data;
    loading.value = false;
  };

  onMounted(async () => {
    loading.value = true;
    await getCustomerSuccessPartners();
    loading.value = false;
  });

  return {
    canGetCustomerSuccessPartners,
    customerSuccessPartners,
    loading,
    selectedDashboardCustomerSuccessPartners,
    getCustomerSuccessPartners,
  };
});
